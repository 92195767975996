// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Lato", 
    $fileName: "lato-v17-latin", 
    $weights: ("300", "400", "700", "900"), 
    $types: all
);