* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;
  font-weight: 300;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

strong, b{
  font-weight: 700;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  color: $primary;
  font-weight: 700;

  span{
    color: $secondary;
    display: block;
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  font-size: rem(26px);
  line-height: 1.5em;
  @include breakpoint(medium) {
    font-size: rem(28px);
    line-height: 1.5em;
  }
  @include breakpoint(giant) {
    font-size: rem($h1Size);
    line-height: 1.5em;
  }
  span{
    font-size: rem(36px);
    line-height: 1.5em;
    @include breakpoint(medium) {
      font-size: rem(40px);
      line-height: 1.5em;
    }
    @include breakpoint(giant) {
      font-size: rem(44px);
      line-height: 1.5em;
    }
  }
}

h2,
.h2 {
  font-size: rem(24px);
  line-height: 1.5em;
  @include breakpoint(medium) {
    font-size: rem(26px);
    line-height: 1.5em;
  }
  @include breakpoint(giant) {
    font-size: rem($h2Size);
    line-height: 1.3em;
  }
  span{
    font-size: rem(32px);
    line-height: 1.5em;
    @include breakpoint(medium) {
      font-size: rem(34px);
      line-height: 1.5em;
    }
    @include breakpoint(giant) {
      font-size: rem(38px);
      line-height: 1.5em;
    }
  }
}

h3,
.h3 {
  font-size: rem(22px);
  line-height: 1.5em;
  @include breakpoint(medium) {
    font-size: rem(24px);
    line-height: 1.5em;
  }
  @include breakpoint(giant) {
    font-size: rem($h3Size);
    line-height: 1.1em;
  }
  span{
    font-size: rem(28px);
    line-height: 1.5em;
    @include breakpoint(medium) {
      font-size: rem(30px);
      line-height: 1.5em;
    }
    @include breakpoint(giant) {
      font-size: rem(34px);
      line-height: 1.5em;
    }
  }
}

h4,
.h4 {
  font-size: rem($h4Size);
}

h5,
.h5 {
  font-size: rem($h5Size);
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;
  text-decoration: none;
  transition: all 300ms;

  &:hover, &:focus, &:active {
    color: $secondary;
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: none;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $border;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 3rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      
      margin-bottom: rem(7px);
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $secondary;

      &:last-child{
        border-bottom: none;
      }

      &:before {
        @extend .icon;
        content:'🗹';
        text-indent: rem(-25px);
        color: $secondary;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: $medium-light;
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

.hinweiser{
  background: #ffc000;
  padding: 30px 18px;
  text-align: center;
  color:$light;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 50px;
  @include breakpoint(medium) {
    text-align: left;
    padding: 30px 30px;
  }
}

/**
 * Tables
 */
main {
  .mobilehinweis{
    background: #ffc000;
    padding: 30px 18px;
    text-align: center;
    color:$light;
    font-weight: 700;
    font-size: 20px;
    @include breakpoint(medium) {
      display: none;
    }
  }

  table:not(.fc-col-header):not(.fc-scrollgrid):not(.fc-scrollgrid-sync-table) {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(medium, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid #f0f0f0;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
      
    }

    td{
      background: #ffffef;
    }
    thead {
      border-collapse: collapse;
      display: table-header-group;
      background: $flaeche !important;
      td{
        background: $flaeche !important;
        font-weight: 700;
      }
    }

    @include breakpoint(medium, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
