body.cms-page-edit {
	#navigation,
	#header,
	#footer {
		display: none;
	}
}
#page-wrap {
	margin: 0 auto;
	max-width: rem(2520px)!important;
}
// HEADER
//////////////////////////////

#header {
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
	position: relative;
	width: 100%;
	height: 520px;
	margin: rem(70px) 0 rem(300px);
	@include responsiveImage('layout/bgHeader.jpg', (tiny, large), false);
	background-size: cover;
	background-position: top;
	@include breakpoint(medium){
		margin: rem(70px) 0 rem(200px);
	}
	@include breakpoint(large){
		height: 660px;
		margin: rem(70px) 0 rem(100px);
	}
	@include breakpoint(huge) {
		height: 900px;
		margin: rem(100px) 0 10rem;
	}
	@include breakpoint(full) {margin: rem(120px) 0 10rem;}

	.claimBeforer {
		transform: translateY(280px);
		@include breakpoint(medium) {
			transform: translateY(150px);
		}
		@include breakpoint(large) {
			transform: translateY(100px);
		}
		@include breakpoint(giant) {
			transform: translateY(30px);
		}
		.claim {
			.links {
				display: grid;
				grid-template-columns: auto;
				grid-row-gap: 10px;
				align-items: center;
				padding: 2rem;
				background: white;
				border-radius: 50px 0 50px 0;
				box-shadow: 5px 5px 15px #27272729;
				@include breakpoint(medium) {
					grid-template-columns: auto auto;
					grid-column-gap: 10px;
				}
				@include breakpoint(giant) {
					grid-template-columns: auto auto auto auto auto ;	
				}
				a {
					@extend .btn;
					font-size: 20px;
					font-weight: bolder;
					border-radius: 0;
					padding: 1.5rem 1.4rem;
					&:first-child {border-radius: 25px 0 0 0;}
					&:last-child {border-radius: 0 0 25px 0;}

					@include breakpoint(giant) {
						font-size: rem(16px);
					}

					@include breakpoint(full) {
						font-size: rem(20px);
					}
				}
			}
		}
	}

	.meldungen {
		display: none;
		@include breakpoint(large) {
			display: block;
			position: absolute;
			background-color: $light;
			padding: 1rem;
			max-width: 450px;
			top: 0;
			left: 15px;
		}
		@include breakpoint(huge) {
			bottom: 240px;
			left: auto;
			top: auto;
			right: 0;
		}
	}

	body:not(.index) & {
		.swiper-header, .claimBeforer, .meldungen {
			display: none;
		}
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	overflow: hidden;
	
	p,
	ol,
	ul,
	dl,
	table:not(.fc-col-header):not(.fc-scrollgrid):not(.fc-scrollgrid-sync-table) {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img, .btn {
		margin-bottom:rem($baseGap*2);
	}
	.img-780 {img {max-width: 780px;}}
	.img-200 {img {max-width: 200px;}}

	.minispace{
		margin-top: 40px;
	}

	body:not(.cms-page-edit) & {
		#meldungen {
			display: block;
			padding: calc(1rem + 30px) 1rem 1rem;
			border-top: 1px solid $primary;
			border-bottom: 1px solid $primary;
			margin-bottom: 8rem;
			@include breakpoint(large) {
				display: none;
			}
		}
	}

	.highlight-ct, .hinweis-ct {
		background-color: $medium-light;
		padding: 2rem 2rem calc(2rem - 30px);
		border-radius: 0 50px 0 50px;
		margin-bottom:rem($baseGap*2);
		@include breakpoint(giant) {
			padding: 3rem 3rem calc(3rem - 30px);
		}
		&.hinweis-ct {color: $alert;}
		dl {dt,dd {background: $light;}}
	}

	.newsBg-ct {
		background-color: $medium-light;
		margin-bottom: rem(60px);
		@include breakpoint(medium) {
			margin-bottom: rem(150px);
		}
		@include breakpoint(large) {
			margin-bottom: rem(250px);
		}
		.newsBg{
			.cms-newsfeed{
				display: flex;
				flex-direction: column-reverse;
			}
			padding: 60px 18px !important;
			@include breakpoint(medium) {
				padding: 100px 60px !important;
			}
			@include breakpoint(large) {
				padding: 160px 100px!important;
			}
		}
	}
	.uvBanner {
		color: $light;

		.claimContainer {
			display: block;
			@include breakpoint(large) {
				display: flex;
				align-items: center;
			}
			.claimer {

				
				@include breakpoint(large) {
					margin-bottom: 0;
				}
				
				span{
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 1rem;
					display: block;
					@include breakpoint(large) {
						font-size: 30px;
						line-height: 42px;
					}
					@include breakpoint(giant) {
						font-size: 38px;
						line-height: 50px;
						margin-bottom: 2rem;
					}
				}
			}
			.line {
				background: $medium-light;
				margin: 0 auto;
				width: 60px;
				height: 1px;
				margin-bottom: 40px;
				@include breakpoint(large) {
					margin: 0 50px;
				}
				@include breakpoint(giant) {
					width: 100px;
					margin: 0 80px;
				}
				@include breakpoint(full) {
					width: 200px;
					margin: 0 120px;
				}
			}
			.popup {
				width: 100%;
				flex-basis: 100%;
				display: flex;
				justify-content: center;
				@include breakpoint(large) {
					width: 10%;
					flex-basis: 10%;
				}
				.video-play-button {
					position: relative;
					border-radius: 100%;
					background: $primary;
					width: rem(70px);
					height: rem(70px);
					display: flex;
					align-items:center;
					justify-content: center;
					cursor: pointer;
					@include breakpoint(huge) {
						width: rem(110px);
						height: rem(110px);
					}
	
					span {
						display: block;
						position: relative;
						z-index: 3;
						width: 0;
						height: 0;
						border-left: 19px solid #fff;
						border-top: 12px solid transparent;
						border-bottom: 12px solid transparent;
						border-radius: 4px;
						@include breakpoint(full) {
							border-left: 30px solid #fff;
							border-top: 18px solid transparent;
							border-bottom: 18px solid transparent;
						}
					}
					&:before {
						content: "";
						position: absolute;
						z-index: 0;
						left: 50%;
						top: 50%;
						transform: translateX(-50%) translateY(-50%);
						display: block;
						width: 80px;
						height: 80px;
						background:lighten($secondary, 5%);
						border-radius: 50%;
						animation: pulse-border 1500ms ease-out infinite;
						@include breakpoint(huge) {
							width: rem(110px);
							height: rem(110px);
						}
						@include breakpoint(full) {
							width: rem(150px);
							height: rem(150px);
						}
					}
					&:after {
						content: "";
						position: absolute;
						z-index: 1;
						left: 50%;
						top: 50%;
						transform: translateX(-50%) translateY(-50%);
						display: block;
						width: 80px;
						height: 80px;
						background: $secondary;
						border-radius: 50%;
						transition: 300ms linear;
						@include breakpoint(huge) {
							width: rem(110px);
							height: rem(110px);
						}
						@include breakpoint(full) {
							width: rem(150px);
							height: rem(150px);
						}
					}
					&:hover {
						&:after {
							background: lighten($primary, 10%);
						}
					}
				}
			}
		}
	}
	@keyframes pulse-border {
	  0% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	  }
	  100% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	  }
	}
	.videoBeforer{
		.videoContainer{
			z-index: 1006;
		}
		.popUpContainer{
			// @include breakpoint(large) {
			// 	margin-top: 69px;
			// }
			// @include breakpoint(huge) {
			// 	margin-top: 97px;
			// }
			label{
				z-index: 1005;
			}
		}
		
		.col{
			padding: 0 !important;
			@include breakpoint(large) {
				padding: 0 18px!important;
			}
		}
		h2{
			color: $light;
		}
		
		.claim{
			background: $primary;
			padding: 40px 10px;
			color:$light;
			text-align: center;

			@include breakpoint(large) {
				text-align: left;
			}
		}
		.cms-container-el{
			min-height: 0;
		}

		.liner{
			height: 1px;
			width: 150px;
			background: #b55273;
			margin: 40px auto;
			@include breakpoint(large) {
				display: none;
			}
			@include breakpoint(giant) {
				display: inline-block;
				margin: 0 30px;
				width: 100px;
			}
			@include breakpoint(full) {
				width: 200px;
			}
		}
		
		@include breakpoint(medium) {
			.claim{
				padding: 40px 25px;
			}
		}
		@include breakpoint(large) {

			.flexer{
				display: flex;
				align-items: center;;
			}
			margin-bottom: rem(250px);
			// z-index:79;
			&:before{
				height: 300px;
				width: 100%;
				background: $primary;
				position: absolute;
				content: '';
				left: -50%;
			}
			.claim{
				padding: 0;
				height: 300px;
				width: 82%;
				position: relative;
				display: flex;
				align-items: center;
				&:after{
					position: absolute;
					background: $primary;
					height: 300px;
					width: 300px;
					border-radius: 100%;
					content: '';
					right: 0;
					margin-right: -140px;
					display: block;
					z-index: -100;
				}
			}
		}
		@include breakpoint(giant) {
			&:before{
				height: 380px;
			}
			.claim{
				height: 380px;
				&:after{
					height: 380px;
					width: 380px;
					margin-right: -180px;
				}
			}
		}
	}
	
	.counterBg{
		background-size: 40px !important;
	
			&.isOnScreen{
				background-size:cover !important;
			}
		padding: 60px 0;
		@include breakpoint(medium) {
			padding: 100px 0;
		}
		@include breakpoint(large) {
			padding: 160px 0 330px 0;
		}
			
		.h3{
			@include breakpoint(large){
				margin-bottom: rem(80px) !important;
			}
		}

		.flexer{
			@include breakpoint(small) {
				column-count: 2;
			}
			@include breakpoint(medium) {
				column-count: 1;
				justify-content: center;
				display: flex;
			}
			@include breakpoint(full) {
				justify-content: space-between;
			}
		}

		.countbubble{
			background: $flaeche;
			border-radius: 30px;
    		text-align: center;
			padding: 15px 15px 30px 15px;
			margin-bottom: 5px;
			@include breakpoint(small) {
				display: grid;
			}
			@include breakpoint(medium) {
				width: 150px;
				height: 150px;
				border-radius: 130px;
				display: flex;
				align-items: center;
				justify-content: center;

				&.lower{
					margin-top: 100px;
				}
			}
			@include breakpoint(large) {
				width: 190px;
				height: 190px;
			}
			@include breakpoint(huge) {
				width: 250px;
				height: 250px;
			}
			@include breakpoint(full) {
				width: 280px;
				height: 280px;
				border-radius: 160px;
			}
		}

		figure{
			figcaption{
				
				font-size: 20px;
				line-height: 20px;

				@include breakpoint(large) {
					line-height: 30px;
					margin-top: 12px;
				}
				
				span{
					font-size:35px;
					line-height: 60px;
					color: $secondary;
					font-weight: 700;
					@include breakpoint(medium) {
						font-size: 28px;
						line-height: 40px;
					}
					@include breakpoint(large){
						font-size:38px;
					}
					@include breakpoint(huge) {
						font-size:60px;
					}
				}
			}
		}
	}
	.counterAfterer{

		
		.col{
			padding: 0 !important;
			@include breakpoint(large) {
				padding: 0 18px!important;
			}
		}
		
		.claim{
			background: $flaeche;
			padding: 50px 18px;
			text-align: center;
			
		}
		@include breakpoint(medium) {
			.claim{
				padding: 40px 25px;
				.flexer{
					display: flex;
					align-items: center;
					justify-content: center;
					.liner{
						height: 1px;
						width: 50px;
						margin: 0 60px;
						background: $medium;
						@include breakpoint(giant) {
							width: 150px;
						}
					}
				}
			}
			p{
				margin-bottom: 0;
				width: 270px;
			}
		}
		@include breakpoint(large) {
			margin-top: -190px;
			z-index: 80;
			&:before{
				height: 300px;
				width: 100%;
				background: $flaeche;
				position: absolute;
				content: '';
				left: -50%;
			}
			.claim{
				padding: 0;
				height: 300px;
				width: 82%;
				position: relative;
				display: flex;
				align-items: center;
				.flexer{
					display: flex;
					align-items: center;
					p{
						width: 450px;
					}
					.liner{
						height: 1px;
						width: 50px;
						margin: 0 60px;
						background: $medium;
						@include breakpoint(giant) {
							width: 150px;
						}
					}
				}
				&:after{
					position: absolute;
					background: $flaeche;
					height: 300px;
					width: 300px;
					border-radius: 100%;
					content: '';
					right: 0;
					margin-right: -140px;
					display: block;
				}
				.claimer{
					.btn{
						z-index: 2;
						position: relative;
					}
				}
			}
		}
		@include breakpoint(giant) {
			&:before{
				height: 380px;
			}
			.claim{
				height: 380px;
				&:after{
					height: 380px;
					width: 380px;
					margin-right: -180px;
				}
			}
		}
		@include breakpoint(full) {
			.claim{
				.flexer{
					p{
						width: 670px;
					}
				}
			}
		}
	}

	.large-auto{
		padding: 0!important;
	}

	.gruppentourSection {
		padding: 60px 0; 
		@include breakpoint(medium) {
			padding: 100px 0; 
		}
		@include breakpoint(large) {
			padding: 200px 0; 
		}

		.btn{
			display: inline-block;
			margin-top: 1.5rem;
			@include breakpoint(large) {
				margin-top: 2.5rem;
			}
		}
	}
}


.AlpakasEvent{
	.AlpakasEventAuswahl{
		color: #668313;
		p{
			margin-bottom: 0px !important;
		}
	}
	
	.AlpakasEventPic{
		@include breakpoint(medium,max) {
			order: 2;
		} 
	}

	.AlpakasEventLine{
		@include breakpoint(medium) {
			display: none;
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer, #footer-subpage {
	background: $flaeche;
	text-align: center;
	padding-bottom: 5rem;

	@include breakpoint(large) {
		text-align: left;
		padding-bottom: 0;
	}

	h3{
		margin-top: 45px;
		@include breakpoint(large) {
			margin-top: 0;
		}
	}

	.large-auto{
		padding: 0!important;
	}

	.mapBefore{
		&:before{
			margin-right: 7px;
		}
	}
	.mapper{
		height: 400px!important;
		@include breakpoint(large) {
			height: 650px!important;
		}
		iframe{
			height: 400px !important;
			@include breakpoint(large) {
				height: 650px !important;
			}
		}
	}
	.infos{
		@include breakpoint(medium) {
			display: flex;
			justify-content: center;
		}
		@include breakpoint(large) {
			display: block;
		}
	}
	.contactInfos{
		margin-bottom: 20px;
		@include breakpoint(small) {
			margin-bottom: 40px;
		}
		.phoneBefore{
			&:before{
				margin-right: 7px;
			}
		}
		.mailBefore{
			&:before{
				margin-right: 7px;
			}
		}
		@include breakpoint(medium) {
			display: flex;
			.phoneBefore{
				margin: 0 25px;
			}
		}
		@include breakpoint(large) {
			display: block;
			margin-bottom: 30px;
			.phoneBefore{
				margin: 0 25px 0 0;
			}
		}
		@include breakpoint(giant) {
			display: flex;
		}
	}

	input{
		text-align: center;
		@include breakpoint(small) {
			width: 48% !important;
			display: inline-block;
			float: left;
			margin-bottom: 0;
		}
	}

	.btn{
		@include breakpoint(small) {
			width: 48%;
			margin-left: 15px;	
		}
	}
}

#footer-subpage {
	background-color: $medium-light;
	padding: 3rem 0 8rem;
	margin-top: 60px;
	@include breakpoint(medium) {
		margin-top: 100px;
	}
	@include breakpoint(large) {
		margin-top: 160px;
		padding: 5rem 2rem 2rem;
	}
	@include breakpoint(huge) {
		padding: 5rem 0 3rem;
	}
	.nav-subpages {
		display: none;
		@include breakpoint(huge) {
			display: block;
			.naviAdd {
				margin: 20px 0;
			}
		}
	}
}

.minispacerer{
	margin-top: 60px;
	@include breakpoint(large) {
		margin-top: 0;
	}
}

.mapBefore{
	&:before {
        @extend .icon;
        @extend .icon-map;
        color: $medium;
    }
}
.mailBefore{
	&:before {
        @extend .icon;
        @extend .icon-mail;
		font-size: 16px;
        color: $medium;
    }
}
.phoneBefore{
	&:before {
        @extend .icon;
        @extend .icon-phone;
        color: $medium;
		font-size: 16px;
    }
}
.openingBefore{
	&:before {
		@extend .icon;
		@extend .icon-clock;
		color: $medium;
	}
}
.fileBefore{
	&:before {
		@extend .icon;
		@extend .icon-file;
		color: $medium;
	}
}
.sunBefore{
	&:before {
		@extend .icon;
		@extend .icon-sun;
		color: $medium;
	}
}
.terminBefore{
	&:before {
		@extend .icon;
		@extend .icon-calendar;
		color: $medium;
	}
}

body:not(.index){
	#header{
		background-image: none;
		background-color: $primary;

		&.lazyLoaded {
			background-size:cover
		}

		.claimBeforer {
			display: none;
		}

		height: 40px;
		margin-bottom: 60px;
		@include breakpoint(medium) {
			margin-bottom: 100px;
		}
		@include breakpoint(large) {
			margin-bottom: 160px;
		}
		@include breakpoint(huge) {
			height: 150px;
		}		
	}
	#footer {
		margin-top: 60px;
		@include breakpoint(medium) {
			margin-top: 100px;
		}
		@include breakpoint(large) {
			margin-top: 160px;
		}
	}
}

body.infos-und-news-aktuelles{
	.loop-child {
		&:last-child{
			hr{
				display: none;
			}
		}
	}
}

.cms-liveeditor-is-opened{
	.collapser{
		.h4{
			color: $primary;
			p{
				color: $primary;
			}
			span{
				color: $primary;
			}
		}
	}
}



body:not(.cms-page-edit) [data-beonly] {
	display: none;
}

.fc-h-event {
	border:none!important
}


body:not(.cms-liveeditor-is-opened){
	.cmsloop{
		display: none;
	}
}

body.preise-und-oeffnungszeiten{
	.mobileLegend{
		background: $flaeche;
		padding: 18px;
		margin-bottom: 25px;
		@include breakpoint(small) {
			column-count: 2;
		}
		@include breakpoint(medium) {
			column-count: 3;
		}
		@include breakpoint(huge) {
			column-count: 5;
		}
		.closed{
			display: flex;
			align-items: center;
			&:before{
				width: 25px;
				height: 25px;
				margin-right: 10px;
				background: $light;
				border: 1px solid $border;
				display: block;
				content: '';
			}
		}
		.first{
			display: flex;
			align-items: center;
			&:before{
				width: 25px;
				height: 25px;
				margin-right: 10px;
				display: block;
				content: '';
				background: rgb(255, 192, 0);
			}
		}
		.second{
			display: flex;
			align-items: center;
			&:before{
				width: 25px;
				height: 25px;
				margin-right: 10px;
				display: block;
				content: '';
				background:rgb(217, 149, 148);
			}
		}
		.third{
			display: flex;
			align-items: center;
			&:before{
				width: 25px;
				height: 25px;
				margin-right: 10px;
				display: block;
				content: '';
				background: rgb(251, 212, 180);
			}
		}
		.fourth{
			display: flex;
			align-items: center;
			&:before{
				width: 25px;
				height: 25px;
				margin-right: 10px;
				display: block;
				content: '';
				background: rgb(178, 161, 199);
			}
		}
	}
	#fullcalendarContainer{
		margin-bottom: 50px;
		.fc-toolbar{
			display: block ;
			@include breakpoint(small) {
				display: flex ;
			}
		}
		.fc-sticky{
			font-size: 0;
			@include breakpoint(large) {
				font-size: 16px;
			}
		}
		.fc-toolbar-chunk{
			.fc-button-primary{
				background-color: $secondary;
				&.fc-button-active {
					background-color: $dark;
					color: $secondary;
				}
			}
		}
		.fc-event-title-container{
			display: flex;
    		justify-content: center;
		}
	}
}


body.anfahrt{
	small{
		margin-bottom: 20px;
	}
}

.datepicker{
	span{
		margin-bottom: 10px;
		display: block;
	}
}

#quickbar{
	.navToggled{
		background-color: $primary;
		padding: 1.5rem;
		span{
			background: white!important;
			&::after, &::before{
				background: white!important;
			}
		}
	}
}