// ACCORDION
// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary; 
$collapser-dark: $light; 

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        padding: 1rem 3rem 1rem 1rem;
        position: relative;
		cursor: pointer;
		font-size: .9rem;
		transition: 300ms all;

		@include breakpoint(medium) {
			font-size: 1.1rem;
		}

        &:not(.collapser-initialized) {
            background: none;
			padding:0;
			transition: 300ms all;

            &:after, &:before {
                display:none;
            }
        }

        & + * {
            overflow:hidden;
			// transition: max-height 300ms;
			transition: 300ms all;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
			}
		}

        @content;
    }
}

.accordion {
	h2 {
		color: $dark;
		background-color: $light;
		box-shadow: 5px 5px 15px #27272729;
		margin-bottom: 1.5rem;
		border-radius: 20px 0 20px 0;
		body.cms-page-edit & {
			color: $dark;
		}

        &:after, &:before {
            width: 15px;
            height: 3px;
            content:"";
            position: absolute;
            right: 1rem;
			transition: all 300ms;
			background: $dark;
		}
		
        &:hover, &.active {
			color: $light;
			background-color: $primary;
			&:after, &:before {
				background: $light;
			}
		}
		
        &.active {	
            &:before {
                transform: rotate(-135deg);
            }
            &:after { 
                transform: rotate(-45deg);
            }
		}
		
        &:after {
            top: 50%;
		}
		
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

		&.active {
			margin-bottom: 4rem;
		}

		&.active + .gridLoopSpacer {
			margin-bottom: 3rem;
		}
	}	
}

// Add your custom shine here ;)
@include collapser {
}

.accordion {
	.cms-container-el {
		min-width: 0!important;
		min-height: 0!important;

		.cms-gallery-image {
			a {
				img {
					margin-bottom: 0;
				}
			}

			figcaption {
				margin: .5rem 0 2rem;
			}
		}
	}
}
// ACCORDION END