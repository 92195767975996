// text
$colorMain: #fff;
$colorMedium: #eee;
$colorLink: #eee;
$colorDark: #464a4d;

// backgrounds
$bgFields: #464a4d;
$bgMain: #333;
$bgSubmit: #3bb54a;
$bgCheckbox: #888a8c;
$bgCheckSlider: #fff;

// misc
$borderStroke: 2px;
$spacing: 10px;
$borderRadius: 4px;
$fontSize: 11px;

// independent badge
.privacy-control-badge {
	body.privacyControlIsOpen & {
		left: -100%;
	}
	
	transform: rotate(90deg) translateX(-100%);
	display: inline-block;
	position: fixed;
	left: 0;
	bottom: rem(75px) !important;
	transform-origin: bottom left;
	background: $bgMain;
	color: $colorMain;
	border-top-left-radius: $borderRadius;
	border-top-right-radius: $borderRadius;
	font-size: rem(11px) !important;
	line-height: rem(10px);
	text-decoration: none;
	padding: $spacing !important;
	transition: 300ms all ease-in-out;
	z-index:96 !important;

	&:hover, &:active, &:visited {
		background: $primary;
		color: $light;
	}

	@include breakpoint(large) {
		font-size:rem(12px);
		line-height: rem(12px);
	}
}
#privacy-control {
	position: fixed;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    overflow: unset;
	background: #333 !important;
    border-radius: 4px;
    color: #fff;
}
#privacy-control::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: -1;
}