// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary; 
$collapser-dark: $light; 

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        position: relative;
        cursor: pointer;
        padding: 1rem 5.5rem 1rem 1.2rem;
        @include breakpoint(medium){
            padding: 1rem 5rem 1rem 1rem;
        }

        .h4{
            margin-bottom: 0;
            color:$light;
        }

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
    .collapser{
        &.cms-container-el{
            min-height: 0;
        }
    }
    .collapsBox{
        &.cms-container-el{
            min-height: 0;
        }
    }
    .inhalt{
        .cms-article{
            margin-bottom: 20px !important;
        }
    }
}

// Add your custom shine here ;)
@include collapser {
    color: $light;
}
 .h4{
     color: $light;
    p{
        margin-bottom: 0;
        color: $light;
    }
    span{
        color:$light; 
        font-size: 22px;
    }
    &.collapser{
            margin-bottom: 0;
            margin-top: 2rem;
        }
    }


.collapser{
    scroll-margin-top: 150px
}