// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: huge; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: .6em rem($baseGap);
	text-decoration: none; 
	color: $navColor;
	font-weight: 400;
	transition: all 300ms;
	@extend %animatedTransform;
	
	@include breakpoint($break) {
		padding: 1.2em 0;
		font-size: rem(14.5px);
	}
	@include breakpoint(giant) {
		padding: 1.2em rem(18px);
		font-size: rem(14.5px);
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: darken($primary, 5%);
	transition: all 300ms;
	@include breakpoint($break) {
		color: $primary;
		background-color: $light;
	}
	@include breakpoint(giant) {
		color: $light;
		background-color: darken($primary, 5%);
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: darken($primary, 5%);
	font-weight: 700;
	@include breakpoint(large) {
		color: $primary;
		background-color: $light;
	}
	@include breakpoint(giant) {
		color: $light;
		background-color: darken($primary, 5%);
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
	color: $medium;

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	color: $medium;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 70px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $dark; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 99999;

	@include breakpoint($break) {
		z-index: 99;
	}

	.fixedInfo{
		display: none;
	}

	.branding {
		display: none;
	}

	nav {
		.col{
			padding: 0!important;
			@include breakpoint($break) {
				padding: 0 18px!important;
			}
		}
		@include ul {
			&.navi {
				li {

					&.buchungsanfrage{
						
						a{
							background: $secondary;
							color: $light;
							transition: all 300ms;
							@include breakpoint(large) {
								padding: 1.2em 18px;
							}
							&:hover, &.active{
								background: darken($primary, 5%);
								transition: all 300ms;
							}
						}
					}
					&.onlinebuchungen{
						a{
							background: darken($secondary, 7.5%);
							color: $light;
							transition: all 300ms;
							@include breakpoint(large) {
								padding: 1.2em 18px;
							}
							&:hover, &.active{
								background: darken($primary, 5%);
								transition: all 300ms;
							}
						}
					}
					@include navButtons {
						@include navButton;
					}
					@include has-sub {
						@include sub {
							background: $navBg;
							.besuch-auf-dem-landgut {display: none;}

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						font-size: 16px;
						color: $medium;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	
	
	@include desktopVersionFunctionality;
	.fixedInfo{
		display: block !important;
		background: $primary;
		color: $light;
		padding: 10px 0;
		a{
			color:$light;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
			
		}
		.mapBefore, .mailBefore, .phoneBefore{
			&:before{
				color: $light;
				margin-right: 7px;
			}
		}
		.facebookBefore{
			&:before{
				content: '';
				background: url('/images/layout/facebook.png');
				width: 24px;
				height: 24px;
				background-size: cover;
				display: inline-block;
				margin-right: 7px;
				position: absolute;
				margin-top: 3px;
			}
			a{
				margin-left: 30px;
			}
		}
		.phoneBefore{
			@include breakpoint(giant) {
				margin: 0 50px;	
			}
		}
		@include breakpoint(large) {
			.flexer{
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.liner{
					background: $light;
					width: 40px;
					height: 1px;
				}
			}
		}
		@include breakpoint(giant) {
			.flexer{
				justify-content: flex-end;
				font-size: 18px;
				.liner{
					width: 40px;
					margin: 0 30px;
				}
			}
		}
	}
	#navigation {

		&.scroll{
			box-shadow: 0 0 14px $medium;	
			.branding {
				img {    
					max-width: 210px;
					padding: 29px 1rem;	
					transition: .5s;
				}
			}
		}
		.branding {
			display: block;
			img {    
				position: absolute;
				width: auto;
				max-width: 210px;
				background: $light;
				padding: 29px 1rem;
				border-radius: 15px;
				transition: .5s;
			}
		}
		nav {
			ul {
				&.navi {
					margin: 2rem 0;
					li {	
						&.anfahrt, &.datenschutzerklaerung, &.impressum, &.newsletter, &.jobs {
							display: none;
						}					
						&.has-sub {
							.sub {
							    box-shadow: 0 0 14px $medium;	
								text-align: center;
								z-index: 999;
								
								a{
									padding: .7em rem(18px);
									border-bottom: 1px solid $border;
									&.infos-und-news-wichtige-informationen, .unser-angebot-gutscheine {
										border-bottom: none !important;
									}
									&:after{
										display: none;
									}
								}
							}
						}
						&.navBack {
							background: $flaeche;
						}
					}
				}
			}
		}
	}
}

@include breakpoint(full) {	
	#navigation {
		&.scroll{
			.branding {
				img {    
					max-width: 255px;
					padding: 1rem;	
					transition: .5s;
				}
			}
		}
		.branding {
			display: block;
			img {    
				max-width: 320px;
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	box-shadow: 5px 5px 15px #27272729;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
	
	.branding {
		display: block;
		img {    
			max-width: 120px;
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: none;
	@include breakpoint(huge) {
		display: flex;
		font-size: 18px;
		justify-content: space-between;
		margin-top: 20px;
	}

	li {
		a{
			display: block;
			background: $primary;
			color:$light;
			padding: 10px 0;
			font-weight: 400;
			border-bottom: 1px solid $light;

			&:hover, &.active{
				background: $medium;
			}

			@include breakpoint(small) {
				border-bottom: none;
				color: $dark;
				padding: 40px 0;
				background: none;
				margin-right: 5px;

				&.impressum{
					margin-right: 0;
				}

				&:hover, &.active{
					color: $primary;
				}
				&.active{
					font-weight: 700;
				}
			}
			@include breakpoint(large) {
				padding: 20px 0 0 0;
				&:hover, &.active{
					background: transparent;
				}
			}
			@include breakpoint(huge) {
				margin-right: 0;
			}
		}
	}
}

@include breakpoint($break, max) {
	li.besuch-auf-dem-landgut-das-landgut-cobenzl {
		padding-bottom: rem(300px);
	}
}